import React, { useState } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import { Form, Button, Card, Col, Row  } from 'react-bootstrap';
import {  useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RingLoader } from 'react-spinners'; // Import RingLoader here
import $, { data } from 'jquery';
const AdAddPage = () => {
  const [loading, setLoadingAddPage] = useState(false); // New state for loading add page
  const [showAddNumberInput, setShowAddNumberInput] = useState(false);
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [showFirstInput, setShowFirstInput] = useState(false); // New state for showing the first input
  const [showForm, setShowForm] = useState(false); // State to control the visibility of the form

    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [builderTypes, setBuilderTypes] = useState([]);
  const [selectedBuilderType, setSelectedBuilderType] = useState('');
  const [numbers, setNumbers] = useState(['']);
  const [formData, setFormData] = useState({
    builderName: '',
    facebookPageName: '',
    adID: '',
    whatsAppNumber: '',
    selectedBuilderType:'',
    // Add other fields here
  });

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const handleAddNumber = () => {
    if (numbers.length < 4) {
      const updatedNumbers = [...numbers, ''];
      setNumbers(updatedNumbers);
    }
  };
  
  const handleRemoveNumber = (index) => {
    const updatedNumbers = [...numbers];
    updatedNumbers.splice(index, 1);
    setNumbers(updatedNumbers);
  };


 

  // const handleAddNumber = () => {
  //   const lastNumber = numbers[numbers.length - 1];
  
  //   if (lastNumber === '') {
  //     const newErrors = { ...errors };
  //     newErrors[`whatsAppNumber${numbers.length}`] = 'Please enter WhatsApp number';
  //     setErrors(newErrors);
  //   } else {
  //     const updatedNumbers = [...numbers, ''];
  //     setNumbers(updatedNumbers);
  //   }
  // };
  

  // const handleRemoveNumber = (index) => {
  //   const updatedNumbers = [...numbers];
  //   updatedNumbers.splice(index, 1);
  //   setNumbers(updatedNumbers);
  // };


  

  useEffect(() => {
    const fetchData = async () => {
      $('#loading').show();
      try {
        const accessToken = localStorage.getItem('access_token');
  
        if (!accessToken) {
          navigate('/');
          console.error('Access token not found in localStorage');
          $('#loading').hide();
          return;
        }
  
        const response = await fetch(`${baseURL}/get_accounts/`, {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Cache-Control': 'no-cache', // Include access token in the headers
          },
        });
  
        const data = await response.json();
        if (data && data.data) {
          setBuilderTypes(data.data);
          $('#loading').hide();
        }
      } catch (error) {
        console.error('Error fetching builder types:', error);
        $('#loading').hide();
      }
    };
  
    fetchData();
  }, []);
  

  const handleBuilderTypeChange = (event) => {
    setSelectedBuilderType(event.target.value);
  };

  const handleSubmit = async (event) => {
    
    event.preventDefault();

    const newErrors = {};

if (!formData.builderName.trim()) {
  newErrors.builderName = 'Builder Name is required.';
}

if (!formData.facebookPageName.trim()) {
  newErrors.facebookPageName = 'Facebook Page Name is required.';
}

if (selectedBuilderType === '') {
  newErrors.selectedBuilderType = 'Account Username is required.';
}

if (!formData.adID.trim()) {
  newErrors.adID = 'Ad ID is required.';
}

if (!formData.whatsAppNumber.trim()) {
  newErrors.whatsAppNumber = 'WhatsApp Number is required.';
}

if (!formData.publishDate) {
  newErrors.publishDate = 'Publish Date is required.';
}
    
    //   setErrors(newErrors);
    // ... apply similar checks for other fields

    // If any errors are found, display them and prevent form submission
    if (Object.keys(newErrors).length > 0) {
        console.log("okaaaaaaaaayyyyyyyyy",newErrors)
      setErrors(newErrors);
      return;
    }

    const multipleNumberInputs = Array.from(event.target.getElementsByClassName('multiple-number-input'));
    const multipleNumbers = multipleNumberInputs
      .map((input) => input.value.trim())
      .filter((value) => value !== '');
    
      const whatsappNumbersObject = {};
      multipleNumbers.forEach((number, index) => {
        whatsappNumbersObject[`whats_app_no${index + 1}`] = number;
      });
      const multiplewhatsappno = JSON.stringify(whatsappNumbersObject); // Convert the object to a JSON string

  const formDataToSend  = {
    account_user: event.target.formBuilderType.value,
    builder_name: event.target.formBuilderName.value,
    facebook_page: event.target.formFacebookPageName.value,
    ad_id: event.target.formAdID.value,
    whats_app_number: event.target.formWhatsAppNumber.value,
    // Add other fields similarly
    multiple_numbers: multiplewhatsappno,
    published_date: event.target.formPublishDate.value,
  };

  console.log('FormData:', formDataToSend );
    
  
  try {
    $('#loading').show();
    const accessToken = localStorage.getItem('access_token');
  
    if (!accessToken) {
      navigate('/');
      console.error('Access token not found in localStorage');
      $('#loading').hide();
      return;
    }
  
    const response = await fetch(`${baseURL}/add_page/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${accessToken}`,
        'Cache-Control': 'no-cache', // Include access token in the headers
      },
      body: JSON.stringify(formDataToSend),
    });
  
    if (response.ok) {
      console.log('Form data sent successfully');
      $('#loading').hide();
      navigate('/home');
    } else {
      const errorData = await response.json(); // Assuming the server sends error details
      console.error('Failed to send form data', errorData);
      setErrors({});
      $('#loading').hide();
  
      // Update input errors based on the server response
      const newErrors = {};
  
      if (errorData && errorData.adID) {
        newErrors.adID = errorData.adID;
      }
  
      if (errorData && errorData.facebook_page) {
        newErrors.facebookPageName = errorData.facebook_page;
      }
  
      setErrors(newErrors);
    }
  } catch (error) {
    console.error('Fetch error:', error);
    $('#loading').hide();
  }
  
  
  };

  
  return (
    <div className="container-fluid">
      
      <div className="row">
        <Sidebar />
        <main className="col-md-9 ms-sm-auto col-lg-9 px-md- mb-4 mt-4">
      <div className="w-75 mx-auto">
      
        <Card>
          <Card.Body> 
          
            <Form onSubmit={handleSubmit} disabled={loading}>
            <Form.Group controlId="formBuilderType">
  <strong>
    <Form.Label className="text-dark">Account Username<span style={{ 'color': "red" }}>*</span></Form.Label>
  </strong>
  <Form.Select
    aria-label="Select Builder Type"
    className={`mb-1 ${errors.selectedBuilderType ? 'border border-danger' : ''}`}
    value={selectedBuilderType}
    onChange={handleBuilderTypeChange}
  >
    <option value="">Select Builder Type</option>
    {Array.isArray(builderTypes) &&
      builderTypes.map((type) => (
        <option key={type.id} value={type.username}>
          {type.username}
        </option>
      ))}
  </Form.Select>
  {errors.selectedBuilderType && (
    <div className="mb-1">
      <small className="text-danger">{errors.selectedBuilderType}</small>
    </div>
  )}
</Form.Group>

    
      <Form.Group controlId="formBuilderName">
  <strong>
    <Form.Label className="text-dark">Builder Name<span style={{'color':"red"}}>*</span></Form.Label>
  </strong>
  <Form.Control
    type="text"
    placeholder="Enter builder name"
    className={`mb-1 ${errors.builderName ? 'border border-danger' : ''}`}
    value={formData.builderName}
    onChange={(e) => setFormData({ ...formData, builderName: e.target.value })}
  />
  {errors.builderName && (
    <div className="mb-1">
      <small className="text-danger">{errors.builderName}</small>
    </div>
  )}
</Form.Group>


              <Form.Group controlId="formFacebookPageName">
  <strong>
    <Form.Label className="text-dark">Facebook Page Name<span style={{ 'color': "red" }}>*</span></Form.Label>
  </strong>
  <Form.Control
    type="text"
    placeholder="Enter Facebook page name"
    className={`mb-1 ${errors.facebookPageName ? 'border border-danger' : ''}`}
    value={formData.facebookPageName}
    onChange={(e) => setFormData({ ...formData, facebookPageName: e.target.value })}
  />
  {errors.facebookPageName && (
    <div className="mb-1">
      <small className="text-danger">{errors.facebookPageName}</small>
    </div>
  )}
</Form.Group>
                
        <Form.Group controlId="formAdID">
  <strong>
    <Form.Label className="text-dark">Ad ID<span style={{'color':"red"}}>*</span></Form.Label>
  </strong>
  <Form.Control
    type="text"
    placeholder="Enter Ad ID"
    className={`mb-1 ${errors.adID ? 'border border-danger' : ''}`}
    value={formData.adID}
    onChange={(e) => setFormData({ ...formData, adID: e.target.value })}
  />
  {errors.adID && (
    <div className="mb-1">
      <small className="text-danger">{errors.adID}</small>
    </div>
  )}
</Form.Group>


<Form.Group controlId="formWhatsAppNumber">
  <strong>
    <Form.Label className="text-dark">WhatsApp Number<span style={{'color':"red"}}>*</span></Form.Label>
  </strong>
  <Form.Control
    type="text"
    placeholder="Enter WhatsApp number"
    className={`mb-1 ${errors.whatsAppNumber ? 'border border-danger' : ''}`}
    value={formData.whatsAppNumber}
    onChange={(e) => setFormData({ ...formData, whatsAppNumber: e.target.value })}
  />
  {errors.whatsAppNumber && (
    <div className="mb-1">
      <small className="text-danger">{errors.whatsAppNumber}</small>
    </div>
  )}
</Form.Group>


{!showForm && (
        <Button variant="secondary" onClick={toggleForm}>
          Add Number
        </Button>
      )}

{showForm && (
  <Form.Group controlId="formMultipleNumbers">
    {numbers.map((number, index) => (
      <Row key={index} className="mb-3">
        <Col md={9}>
          <Form.Control
            type="text"
            placeholder={`Enter Number ${index + 1}`}
            value={number}
            onChange={(e) => {
              const updatedNumbers = [...numbers];
              updatedNumbers[index] = e.target.value;
              setNumbers(updatedNumbers);
            }}
            className={`multiple-number-input`}
          />
        </Col>
        <Col md={3}>
        <Button
  variant="danger"
  onClick={() => handleRemoveNumber(index)}
  disabled={numbers.length === 0} // Disable Remove button only if there's a single input field
>
  Remove
</Button>
        </Col>
      </Row>
    ))}
    {numbers.length < 4 && (
      <Button variant="secondary" onClick={handleAddNumber}>
        Add Number
      </Button>
    )}
  </Form.Group>
)}






<Form.Group controlId="formPublishDate">
  <strong>
    <Form.Label className="text-dark">Publish Date<span style={{'color':"red"}}>*</span></Form.Label>
  </strong>
  <Form.Control
    type="date"
    className={`mb-1 ${errors.publishDate ? 'border border-danger' : ''}`}
    value={formData.publishDate}
    onChange={(e) => setFormData({ ...formData, publishDate: e.target.value })}
  />
  {errors.publishDate && (
    <div className="mb-1">
      <small className="text-danger">{errors.publishDate}</small>
    </div>
  )}
</Form.Group>


              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
            <div id="loading">
  <div class="loader">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="loader-text">Loading...</div>
  </div>
</div>
                <div className="loader-container">
                  <Col md={{ span: 9, offset: 1 }}>
                    <RingLoader size={100} color={'#FF0000'} loading={loading} />
                  </Col>
                </div>

          </Card.Body>
        </Card>
        
      </div>
    </main>
      </div>
    </div>
  );
};

export default AdAddPage;

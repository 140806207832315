import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './account/login';
import Header from './account/header';
import Dashboard from './dashboard/dashboard';
import AdAddPage from './AddPage/addfbpage';
import FileUploadComponent from './AddPage/importleads';
import SendLeads from './AddPage/sendlead';
import DownloadLeads from './AddPage/downloadleads.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import Analysis from './AddPage/analysis.js';
import Accounts from './AddPage/accounts.js';

const App = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/home" element={<Dashboard />} />
        <Route exact path="/add_page" element={<AdAddPage />} />
        <Route exact path="/import_leads" element={<FileUploadComponent />} />
        <Route exact path="/send_leads" element={<SendLeads />} />
        <Route exact path="/download_leads" element={<DownloadLeads />} />
        <Route exact path="/analysis" element={<Analysis />} />
        <Route exact path="/accounts" element={<Accounts />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
